import React, { useState, createContext } from "react";

export let UserContext = createContext();

export let UserProvider = props => {
    let currentUser = JSON.parse(localStorage.getItem("user"))
    let iniateUser = currentUser ? currentUser : null
    let [user, setUser] = useState(iniateUser);

    return (
        <UserContext.Provider value={[user, setUser]}>
            {props.children}
        </UserContext.Provider>
    );
};