import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { UserProvider } from './context/userContext'
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));


const Login = React.lazy(() => import('./routes/Login'));
// const Register = React.lazy(() => import('./views/Register'));

class App extends Component {

  render() {
    return (
      <UserProvider>
        <HashRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </UserProvider>
    );
  }
}

export default App;
